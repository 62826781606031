.nav-main {
  ul {
    padding: 0 60px 0;
    margin: 0;
    list-style: none;
    @include breakpoint-down(md){
      display: flex;
      padding: 0;
      justify-content: space-between;
    }
    
    li {
      margin-bottom: 30px;
      &.active {
        a{
          color: #FFF;
          img{
            opacity: 1;
          }
        }
      }
      
      @include breakpoint-down(md){
        margin-bottom: 0;
      }
    }
    
    a {
      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 260%;
      color: #FFB23E;
      transition: color .2s ease-in-out;
      position: relative;
      &:hover{
        color: #FFF;
      }
      
      @include breakpoint-down(md){
        font-size: 14px;
      }
      
      img{
        @include stretch(-35px, 0, 0, -25px);
        opacity: 0;
        transition: opacity .2s ease-in-out;
        @include breakpoint-down(md){
          display: none;
        }
      }
    }
  }
}

.header {
  width: 328px;
  background: #FF9900;
  box-shadow: 16px 4px 64px rgba(18, 61, 101, 0.3);
  backdrop-filter: blur(100px);
  border-radius: 0 68px 68px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include breakpoint-down(md){
    width: 100%;
    border-radius: 0 0;
    display: block;
    padding: 10px 10px 0;
    height: 100px;
  }
  
  
  &__logo{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    @include breakpoint-down(md){
      position: relative;
    }
    
    img{
      //max-width: 100%;
      object-fit: cover;
      height: 100%;
      
      @include breakpoint-down(md){
        width: 100%;
      }
    }
  
    .mobi{
      display: none;
    }
    
    @include breakpoint-down(md){
      .desc{
        display: none;
      }
      
      .mobi{
        display: block;
      }
    }
  }
}

.about-service{
  display: flex;
  align-items: center;
  height: 100vh;
  padding-left: 70px;
  
  @include breakpoint-down(md){
    padding: 20px 20px;
    display: block;
    height: auto;
  }
  
  .flex{
    display: flex;
    align-items: center;
    
    & > div:first-child{
      margin-right: 18px;
      @include breakpoint-down(md){
        width: 60px;
        
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  
  .description{
    color: #31456A;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    @include breakpoint-down(md){
      font-size: 17px;
      line-height: 22px;
    }
  }
}

.support{
  padding: 220px 0 0 70px;
  @include breakpoint-down(md){
    padding: 20px;
  }
  
  .description{
    color: #31456A;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    
    @include breakpoint-down(md){
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 30px;
    }
  }
  
  .flex{
    display: flex;
    margin-bottom: 80px;
    align-items: center;
    @include breakpoint-down(md){
      display: block;
    }
    
    .send-mail-btn{
      margin-left: 28px;
      position: relative;
      @include breakpoint-down(md){
        margin-left: 0;
      }
  
      span{
        position: absolute;
        left: -170px;
        bottom: -100px;
        @include breakpoint-down(md){
          display: none;
        }
      }
    }
  }
}

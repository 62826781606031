.tariff {
  padding: 62px 0 70px 70px;
  height: 100vh;
  overflow: auto;
  
  @include breakpoint-down(md){
    padding: 20px;
  }
  
  .flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .nav-tabs {
    padding: 0;
    margin: 0 0 60px 0;
    list-style: none;
    display: flex;
    
    @include breakpoint-down(md){
      margin: 0 0 20px 0;
      justify-content: space-between;
    }
    
    .nav-item {
      margin-right: 32px;
      
      &:last-child {
        margin: 0;
      }
      
      @include breakpoint-down(md){
        margin: 0;
      }
    }
    
    .nav-link {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 25px;
      color: #6E798C;
      transition: color .2s ease-in-out;
      @include breakpoint-down(md){
        font-size: 12px;
        line-height: normal;
        padding: 0;
      }
      
      &.active {
        color: #000;
      }
    }
  }
  
  &-item {
    display: flex;
    align-items: flex-start;
    
    @include breakpoint-down(md){
      display: block;
    }
    
    .left {
      background: #FFFFFF;
      border-radius: 16px;
      padding: 19px 18px 40px 18px;
      width: 445px;
      margin-right: 30px;
      @include breakpoint-down(md){
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0;
      }
    }
    
    .right {
      .price-block{
        background: #FFFFFF;
        border-radius: 16px;
        padding: 19px 18px 4px 18px;
        width: 348px;
        margin-bottom: 30px;
        
        @include breakpoint-down(md){
          width: 100%;
        }
  
      }
      
      .title {
        color: #A5ADBB;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 5px;
      }
      
      .yellow {
        color: #FF9900 !important;
      }
      
      .price-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 41px;
        letter-spacing: 0.03em;
        color: #081F32;
        
        .period {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 25px;
          color: #A5ADBB;
          width: 70px;
        }
        
        .text-price{
          width: 240px;
        }
      }
    }
    
    .title {
      color: #FF9900;
      font-style: normal;
      font-weight: 800;
      font-size: 28px;
      line-height: 25px;
      margin-bottom: 33px;
    }
    
    .info-list {
      padding: 10px 12px 0 0;
      margin: 0;
      list-style: none;
      border-top: solid 2px #EBECED;
      
      li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        color: #6E798C;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        
        &:last-child {
          margin-bottom: 0;
        }
        
        .img {
          width: 18px;
          height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
          
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.requisites{
  padding-top: 18px;
  border-top: solid 2px #EBECED;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #6E798C;
  margin-top: 11px;
}

.nav-tabs .nav-link{
  border: none;
}

.nav-tabs{
  border: none;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  background-color: transparent;
}

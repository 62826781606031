.main-slider {
  .swiper-slide {
    padding: 19vh 0 0 50px;
    height: 100vh;
    position: relative;
    overflow: hidden;
    @include breakpoint-down(md){
      padding: 20px 20px 30px;
      height: calc(100vh - 100px);
    }
    
    &-active {
      .slide__img {
        opacity: 1;
      }
      
      .slide__sup-title {
        opacity: 1;
        transform: translate(0);
      }
      
      h2.big-title {
        opacity: 1;
        transform: translate(0);
      }
      
      .slide__description {
        opacity: 1;
        transform: translate(0);
      }
      
      .modal-btn {
        opacity: 1;
      }
    }
    
    &-duplicate {
      .slide__img {
        opacity: 1;
      }
    }
  }
  
  h2.big-title {
    opacity: .5;
    transform: translate(330px, 0);
    transition: opacity .1s linear, transform 1s ease-in-out;
    transition-delay: 1.2s;
  }
  
  .modal-btn {
    opacity: 0;
    transition: opacity .4s ease-in-out;
    transition-delay: 2.1s;
  }
  
  .slide {
    &__sup-title {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 29px;
      color: #31456A;
      margin-bottom: 14px;
      opacity: .5;
      transform: translate(300px, 0);
      transition: opacity .1s linear, transform 1s ease-in-out;
      transition-delay: .9s;
    }
    
    &__description {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 163.78%;
      color: #31456A;
      margin-bottom: 105px;
      opacity: .5;
      transform: translate(360px, 0);
      transition: opacity .1s linear, transform 1s ease-in-out;
      transition-delay: 1.4s;
      
      @include breakpoint-down(md){
        margin-bottom: 45px;
        font-size: 15px;
      }
    }
    
    &__img {
      width: 29vw;
      position: absolute;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: opacity .5s ease-in-out;
      @include breakpoint-down(md){
        width: 180px;
      }
      
      img {
        max-width: 100%;
      }
    }
  }
}

.swiper-pagination-bullet {
  height: 13px;
  width: 13px;
  background: #FFf;
  opacity: 1;
  box-shadow: 0 2px 7px rgba(0, 0, 0, .3);
  transition: background .3s ease-in-out;
  
  &-active {
    background: #FF9900;
  }
}

.swiper-pagination {
  text-align: left;
  padding-left: 48px;
}

.modal{
  &-dialog{
  max-width: 445px;
  }
  
  &-header{
    justify-content: center;
    border: none;
    
    .modal-title{
      color: #31456A;
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 25px;
    }
    
    .subtitle{
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      text-align: center;
      color: #B3B3B3;
      margin-bottom: 45px;
    }
  }
  
  &-content{
    background: #FFFFFF;
    border-radius: 16px;
    border: none;
  }
}

html,
body,
.wrapper {
	height: 100%;
}

body {
	color: $text-color;
  background: #fff;
  background: radial-gradient(100% 111.89% at 100% 100%, #E5ECF5 0%, rgba(255, 255, 255, 0) 100%);
	max-width: 100%;
}

.wrapper-in { // wrapper
	position: relative;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

.content {
	overflow: hidden;
	flex: 1;
}

.flex-layout{
  display: flex;
  height: 100vh;
  @include breakpoint-down(md){
    height: auto;
    flex-direction: column;
  }
}


// Using @font-face
// Just only three file formats required
// no need to include files with svg or eot extensions
// With these three formats we have pretty good browsers support e.g.
// Chrome 3.5+, Safari 3+, Firefox 3.5+, Opera 10.1+, IE9+, Android 2.2+, iOS 4.3+

// @font-face {
//   font-family: "PT Sans";
//   src:
//     inline("#{$path-fonts}/pt_sans-web-regular-webfont.woff2") format("woff2"),
//     inline("#{$path-fonts}/pt_sans-web-regular-webfont.woff") format("woff");
//     // inline("#{$path-fonts}/pt_sans-web-regular-webfont.ttf")  format("truetype");
//   font-style: normal;
//   font-weight: normal;
// }


@font-face {
  font-family: 'Inter';
  src: url('#{$path-fonts}/Inter-Regular.woff2') format('woff2'),
  url('#{$path-fonts}/Inter-Regular.woff') format('woff'),
  url('#{$path-fonts}/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('#{$path-fonts}/Inter-Bold.woff2') format('woff2'),
  url('#{$path-fonts}/Inter-Bold.woff') format('woff'),
  url('#{$path-fonts}/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('#{$path-fonts}/Inter-ExtraBold.woff2') format('woff2'),
  url('#{$path-fonts}/Inter-ExtraBold.woff') format('woff'),
  url('#{$path-fonts}/Inter-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('#{$path-fonts}/Inter-Bold_1.woff2') format('woff2'),
  url('#{$path-fonts}/Inter-Bold_1.woff') format('woff'),
  url('#{$path-fonts}/Inter-Bold_1.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('#{$path-fonts}/Inter-Regular_1.woff2') format('woff2'),
  url('#{$path-fonts}/Inter-Regular_1.woff') format('woff'),
  url('#{$path-fonts}/Inter-Regular_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('#{$path-fonts}/Inter-ExtraBold_1.woff2') format('woff2'),
  url('#{$path-fonts}/Inter-ExtraBold_1.woff') format('woff'),
  url('#{$path-fonts}/Inter-ExtraBold_1.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$path-fonts}/OpenSans-SemiBold.woff2') format('woff2'),
  url('#{$path-fonts}/OpenSans-SemiBold.woff') format('woff'),
  url('#{$path-fonts}/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


.content {
  .container-fluid {
    position: relative;
    z-index: 1;
    @include breakpoint-down(md){
      min-height: calc(100vh - 100px);
    }
  }
  
  position: relative;
}

.animate-layer {
  @include stretch();
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .5;
  pointer-events: none;
  
  .balloon-wrapper {
    width: 626px;
    height: 626px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: rotate 22s linear infinite;
  }
  
  .big-balloon {
    animation: big-balloon 10s linear infinite;
  }
  
  img {
    position: absolute;
  }
  
  .balloon {
    animation: balloon 11s linear infinite;
    opacity: .3;
    
    & + .balloon {
      animation: balloon2 9s linear infinite;
      animation-delay: 2s;
      left: 0;
      bottom: 0;
      opacity: .3;
    }
  }
  
  .small-balloon{
    animation: small-balloon 19s linear infinite;
    opacity: .3;
  
    & + .small-balloon {
      animation: small-balloon2 9s linear infinite;
      animation-delay: 2s;
      right: 0;
      bottom: 0;
      opacity: .3;
    }
  }
}

@keyframes big-balloon {
  0% {
    transform: scale(1) rotate(0);
    opacity: .3;
  }
  
  25% {
    transform: scale(1.02) rotate(90deg);
    opacity: .8;
  }
  
  50% {
    transform: scale(1) rotate(180deg);
    opacity: .3;
  }
  
  75% {
    transform: scale(1.02) rotate(270deg);
    opacity: .8;
  }
  
  100% {
    opacity: .3;
    transform: scale(1) rotate(360deg);
  }
}

@keyframes balloon {
  0% {
    left: 0;
    opacity: .3;
    transform: scale(1);
  }
  
  25% {
    left: -160px;
    opacity: .8;
    transform: scale(1.02);
  }
  
  50% {
    left: -190px;
    opacity: 1;
    transform: scale(1);
  }
  
  75% {
    left: -160px;
    opacity: .8;
    transform: scale(1.02);
  }
  
  100% {
    left: 0;
    transform: scale(1);
    opacity: .3;
  }
}

@keyframes balloon2 {
  0% {
    left: 0;
    bottom: 0;
    opacity: .3;
    transform: scale(1) rotate(0);
  }
  
  25% {
    left: -160px;
    bottom: -120px;
    opacity: .8;
    transform: scale(1.02) rotate(90deg);
  }
  
  50% {
    left: -190px;
    bottom: -150px;
    opacity: 1;
    transform: scale(1) rotate(180deg);
  }
  
  75% {
    left: -160px;
    bottom: -120px;
    opacity: .8;
    transform: scale(1.02) rotate(270deg);
  }
  
  100% {
    left: 0;
    bottom: 0;
    transform: scale(1) rotate(360deg);
    opacity: .3;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(360deg)
  }
}

@keyframes small-balloon {
  0% {
    right: 0;
    opacity: .3;
    transform: scale(1);
  }
  
  25% {
    right: -160px;
    opacity: .8;
    transform: scale(1.02);
  }
  
  50% {
    right: -190px;
    opacity: 1;
    transform: scale(1);
  }
  
  75% {
    right: -160px;
    opacity: .8;
    transform: scale(1.02);
  }
  
  100% {
    right: 0;
    transform: scale(1);
    opacity: .3;
  }
}

@keyframes small-balloon2 {
  0% {
    right: 0;
    bottom: 0;
    opacity: .3;
    transform: scale(1) rotate(0);
  }
  
  25% {
    right: -160px;
    bottom: -120px;
    opacity: .8;
    transform: scale(1.02) rotate(90deg);
  }
  
  50% {
    right: -190px;
    bottom: -150px;
    opacity: 1;
    transform: scale(1) rotate(180deg);
  }
  
  75% {
    right: -160px;
    bottom: -120px;
    opacity: .8;
    transform: scale(1.02) rotate(270deg);
  }
  
  100% {
    right: 0;
    bottom: 0;
    transform: scale(1) rotate(360deg);
    opacity: .3;
  }
}

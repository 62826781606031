.scroll-text{
  padding: 50px;
  height: 100vh;
  overflow: auto;
  color: #31456A;
  
  h2{
    margin-bottom: 45px;
  }
}

.text-page-link{
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-decoration-line: underline;
  color: #FF9900;
  
  &:hover{
    color: #FF9900;
    text-decoration-line: none;
  }
}

.text-page-link-wrap{
  padding-right: 100px;
}

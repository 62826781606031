$btn-font-size: $font-size-base !default;
$btn-line-height: $font-size-base !default;
$btn-box-shadow: inset 0 3px 5px rgba(black,.125);
$btn-disabled-opacity: .65;
$btn-border-width: 1px !default;
// *************************************************************************

.btn {
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: $btn-border-width solid transparent;
	white-space: nowrap;
	font-size: $btn-font-size;
	line-height: $btn-line-height;
	user-select: none;
	transition-property: box-shadow, background-color;
	transition-duration: .2s;
	outline: none !important;
	text-decoration: none !important;
	padding: 6px 12px;

	&:active,
	&.active {
		box-shadow: $btn-box-shadow;
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		cursor: not-allowed;
		opacity: $btn-disabled-opacity;
		box-shadow: none;
	}

}

.modal-btn{
  background: linear-gradient(0deg, #E3EDF7, #E3EDF7);
  box-shadow: -4px -2px 16px #FFFFFF, 4px 2px 16px rgba(136, 165, 191, 0.48);
  border-radius: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 163.78%;
  color: #31456A;
  padding: 12px 32px 14px 28px;
  transform: translate(0);
  transition: box-shadow .2s ease-in-out, transform .2s ease-in-out;
  
  &:hover{
    box-shadow: -4px -2px 16px #FFFFFF, 4px 2px 16px rgba(136, 165, 191, 0.87);
    transform: translate(0, -1px);
  }
  
  @include breakpoint-down(md){
    padding: 8px 28px 10px 24px;
    font-size: 14px;
  }
}

.send-mail-btn{
  color: #31456A;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 163.78%;
  border: 1px dashed #6E798C;
  border-radius: 37px;
  padding: 23px 17px 22px;
  @include breakpoint-down(md){
    border: none;
    padding: 0;
    font-size: 14px;
  }
}

.order-tariff{
  background: linear-gradient(0deg, #FF9C08, #FF9C08);
  box-shadow: -4px -2px 16px #FFFFFF, 4px 2px 16px rgba(136, 165, 191, 0.48);
  border-radius: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 163.78%;
  padding: 13px 84px 15px;
  color: #FFFFFF;
}

a.btn {
	&.disabled,
	fieldset[disabled] & {
		pointer-events: none;
	}
}

.btn-block {
	display: block;
	width: 100%;
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
	&.btn-block {
		width: 100%;
	}
}

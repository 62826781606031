@import "normalize";

@import "../../node_modules/swiper/swiper-bundle.min.css";
@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

// Core variables and mixins
@import "functions";
@import "variables";
@import "variables-responsive";

// Mixins
@import "mixins";
@import "mixins/breakpoints";
@import "mixins/grid";

// Shared components
@import "fonts";
@import "icons";

// Core CSS
@import "reboot";
@import "type";
@import "helpers";
@import "grid";
@import "flex";
@import "masonry";
@import "tables";
@import "forms";
@import "buttons";
@import "tabs";
@import "layout";
@import "carousels";
@import "modal";


// Components
@import "./components/main";
@import "./components/main-slider";
@import "./components/about-service";
@import "./components/support";
@import "./components/tariff";
